<template>
  <div class="page-mechanism">
    <item-title :title="{name: '合作机构', en_1: 'OUR', en_2: 'PARTNERS'}" />
    <div class="dis-culture">
      <div class="btn-select-group">
        <span class="btn" :class="{active: item.id==navId}" v-for="(item, index) in nav" :key="index" @click="tap(item)">{{item.name}}</span>
      </div>
      <div class="rank">排名不分先后</div>
      <div class="list">
        <load ref="LoadMore" @loadMore="loadMore">
          <transition-group tag="div" enter-active-class="fadeInUp" leave-active-class="fadeOutUp" class="listGroup">
            <div class="li " :class="'li-'+parseInt(index/5)%3" v-for="(item, index) in list" :key="item.id" :style="{'animation-delay':parseInt(index/5)%3/10+'s'}"><img :src="item.logo" alt=""></div>
          </transition-group>
        </load>
      </div>
      <!-- <div class="btner disabled" v-if="loaded">无更多数据</div> -->
      <div class="btner disabled" v-if="loaded"> </div>
      <div class="btner" v-else @click="loadMore"><img v-if="loading" class="loading-icon" src="../../assets/img/common/loading.png" alt=""> 展示更多内容+</div>
    </div>
  </div>
</template>

<script>
import ItemTitle from '@/components/common/itemTitle'
import Load from '@/components/disclosure/load.vue'
import { getPartnerCompanyTypeList, getPartnerCompanyList } from '@/api/disclosure'
export default {
  components: {
    ItemTitle,
    Load
  },
  data() {
    return {
      display: false,
      nav: [
        { id: 0, name: '全部', active: true }
      ],
      navId: 0,
      maxPage: 0,
      page: 1,
      list: [],
      loading: false,
      loaded: false
    }
  },
  mounted() {
    this.getNav();
  },
  methods: {
    async getNav() {
      var res = await getPartnerCompanyTypeList();
      const { list } = res;
      list.forEach(function (item) {
        item.active = false;
      })
      this.nav = [this.nav[0], ...list];
      this.getData();
    },
    async getData() {
      this.loading = true;
      var params = {
        type: this.navId != 0 ? this.navId : '',
        page: this.page,
        limit: 15
      }
      var res = await getPartnerCompanyList(params);
      const { records } = res;
      this.list = [...this.list, ...records];
      this.$emit('loading', false)
      this.maxPage = res.pages;
      this.loading = false;
      if (this.page >= this.maxPage) {
        this.loaded = true;
      }

    },
    loadMore() {
      if (this.page >= this.maxPage) {
        return;
      }
      this.page = this.page + 1;
      this.getData();
    },
    tap(item) {
      this.navId = item.id;
      this.list = [];
      this.page = 1;
      this.loaded = false
      this.getData();

    }
  }
}
</script>

<style lang="scss" scoped>
.page-mechanism {
  .btner {
    width: 2.2rem;
    height: 0.48rem;
    background: #ffffff;
    border-radius: 0.24rem;
    font-size: 0.18rem;
    color: #666666;
    text-align: center;
    line-height: 0.48rem;
    margin: 0 auto;
    margin-bottom: 0.7rem;
    cursor: pointer;
    &.disabled {
      cursor: none;
    }
  }
  .list {
    width: 100%;
    min-height: 2rem;
    margin-bottom: 0.6rem;
    .listGroup {
      display: flex;
      flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      justify-content: left;
    }
    .li {
      width: 1.88rem;
      height: 0.8rem;
      border: 1px solid #ebebeb;
      display: flex;
      align-items: center;
      justify-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 0.15rem;
      margin-right: 0.25rem;
      background: #fff;
      &.fadeOutUp {
        animation-duration: 0s !important;
        animation-delay: 0s !important;
      }
      &:nth-of-type(5n) {
        margin-right: 0;
      }
      img {
        max-width: 100%;
        height: 100%;
        //max-height: 0.7rem;
        //margin-top: 0.05rem;
        display: inline-block;
      }
    }
  }
  .rank {
    font-size: 16px;
    color: #cccccc;
    padding: 0.3rem 0;
  }
  .btn-select-group {
    margin-top: 0.46rem;
  }
  .slide-move {
    transition: all 0.5s;
  }
  .slide-leave-active {
    transform: translate3d(-100%, 0, 0);
  }
  .slide-leave-active {
    position: absolute;
    transform: translate3d(-100, 0, 0);
  }
  .slide-leave-to {
    position: absolute;
    transform: translate3d(-100%, 0, 0);
  }
}
@media only screen and (max-width: $moble_width) {
  .page-mechanism {
    .list {
      .li {
        margin: 0;
        width: 30.5%;
        margin-right: 0.14rem;
        margin-bottom: 0.15rem;
        &:nth-of-type(5n) {
          margin-right: 0.14rem;
        }
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
