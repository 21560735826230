/*
 * @Author       : your name
 * @Date         : 2020-11-25 15:48:34
 * @LastEditTime : 2020-12-02 18:32:54
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/api/home.js
 */
import request from '@/helper/request'

export function getPartnerCompanyTypeList() {
  return request({
    url: 'api/partner-company-type-list',
    method: 'get'
  })
}

export function getPartnerCompanyList(params) {
  return request({
    url: 'api/partner-company-list',
    method: 'post',
    data: params
  })
}

export function getPartnerWebsiteList(params) {
  return request({
    url: 'api/partner-website-list',
    method: 'post',
    data: params
  })
}

export function getProductList(params) {
  return request({
    url: 'api/product-list',
    method: 'post',
    data: params
  })
}

export function getCommonPageData(params) {
  return request({
    url: 'api/pages',
    method: 'get',
    params: params
  })
}

export function getWebInsureList(params) {
  return request({
    url: 'api/web-insure-list',
    method: 'post',
    data: params
  })
}
export function getNetSaleList(params) {
  return request({
    url: 'api/net-sale-list',
    method: 'post',
    data: params
  })
}